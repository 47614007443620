
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa } from "@/interfaces";
import * as types from "@/store/types";
import { sleep } from "@/utils";
@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CardStats: () => import("@/components/layout/CardStasService.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
    // watch: {
    //     overlay(val) {
    //         val &&
    //             setTimeout(() => {
    //                 this.overlay = false;
    //             }, 3000);
    //     }
    // }
})
export default class Summary extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    showAll = false;
    showFilter = true;
    overlay = false;
    color = "lighten-3";
    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    proyectos: any[] = [];
    installations: string[] = [];
    installs: string[] = [];

    listElements: string[] = [];
    paramElments: any[] = [];
    elements: string[] = [];
    selected: string[] = [];
    elementos: any[] = [];

    services: object = {};
    listServices: string[] = [];
    paramsServices: any[] = [];
    selectedServices: object = {};
    servicios: any[] = [];
    auxServ: string[] = [];

    Pmodo = "Contrato";
    modos = ["Contrato", "Precio de Mercado"];
    show = false;
    title = "Servicio Electricidad Diario test";
    stat = 25;
    p = -2;
    loading = 0;
    cabecera = [
        {
            text: "Fecha Inicio",
            align: "start",
            value: "Finicio"
        },
        {
            text: "Fecha Final",
            align: "start",
            value: "Ffinal"
        },
        {
            text: "Nombre",
            align: "start",
            value: "Titulo"
        },
        {
            text: "Descripción",
            align: "start",
            value: "Descripcion"
        },
        {
            text: "Instalación/Elemento",
            align: "start",
            value: "Elemento"
        }
    ];
    default = {
        consolidado: [
            {
                Servicio: "Electrico",
                PdiarioActual: 0,
                PdiarioCambio: 0,
                PsemanalActual: 0,
                PsemanalCambio: 0,
                PmensualActual: 0,
                PmensualCambio: 0,
                EdiarioActual: 0,
                EdiarioCambio: 0,
                EsemanalActual: 0,
                EsemanalCambio: 0,
                EmensualActual: 0,
                EmensualCambio: 0,
                Unit: "kWh"
            }
        ],
        ahorro: 0,
        proyectos: 0
    };

    dummy = {
        ahorro: "-402.981",
        consolidado: [
            {
                EdiarioActual: 5,
                EdiarioCambio: 0.2,
                EmensualActual: 1018,
                EmensualCambio: -8,
                EsemanalActual: 205,
                EsemanalCambio: -20,
                PdiarioActual: 48,
                PdiarioCambio: 0.32,
                PmensualActual: 9216,
                PmensualCambio: -8.49,
                PsemanalActual: 1857,
                PsemanalCambio: -20.28,
                Servicio: "Agua",
                Unit: "m\u00b3"
            },
            {
                EdiarioActual: 2633,
                EdiarioCambio: -43,
                EmensualActual: 562269,
                EmensualCambio: -2,
                EsemanalActual: 112873,
                EsemanalCambio: -21,
                PdiarioActual: 527,
                PdiarioCambio: -43.29,
                PmensualActual: 112454,
                PmensualCambio: -2.47,
                PsemanalActual: 22575,
                PsemanalCambio: -21.45,
                Servicio: "Electrico",
                Unit: "kWh"
            },
            {
                EdiarioActual: 270,
                EdiarioCambio: -8,
                EmensualActual: 25280,
                EmensualCambio: -7,
                EsemanalActual: 5304,
                EsemanalCambio: -18,
                PdiarioActual: 1708,
                PdiarioCambio: -8.46,
                PmensualActual: 160025,
                PmensualCambio: -7.03,
                PsemanalActual: 33576,
                PsemanalCambio: -18.4,
                Servicio: "Gas",
                Unit: "m\u00b3"
            },
            {
                EdiarioActual: 219,
                EdiarioCambio: -17,
                EmensualActual: 21376,
                EmensualCambio: -8,
                EsemanalActual: 4389,
                EsemanalCambio: -21,
                PdiarioActual: 822,
                PdiarioCambio: -16.74,
                PmensualActual: 80160,
                PmensualCambio: -8.13,
                PsemanalActual: 16459,
                PsemanalCambio: -21.14,
                Servicio: "Nitrogeno",
                Unit: "m\u00b3"
            }
        ],
        proyectos: 9
    };
    consolidado = JSON.parse(JSON.stringify(this.default));
    responde: any = [];
    showButton = false;
    finCadena = false;
    Nproject = "";
    first = true;

    mounted() {
        this.fetchUser();
    }

    async fetchUser() {
        if (this.role == "client") {
            this.setProgressBar(true);
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.company = userClientResponse.data[0];
                    this.client = userClientResponse.data[1];
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setProgressBar(false);
            }
        } else if (this.role == "admin") {
            this.fetchCompany();
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        this.overlay = true;
        this.loading = 1;
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            if (this.showButton && this.finCadena) {
                this.overlay = false;
            }
        }
    }

    async fetchClient() {
        this.overlay = true;
        this.loading = 2;
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.companies[0].split(" - ")[0]
            );
            this.consolidado = JSON.parse(JSON.stringify(this.default));
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.client = this.clients[0];
                this.fetchInstallation();
                this.fetchProject();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            if (this.showButton) {
                this.overlay = false;
            }
        }
    }

    async fetchProject() {
        try {
            const projectResponse = await this.$api.getProject<rdsa>(
                this.client.split(" - ")[0]
            );
            if (projectResponse.ok) {
                this.proyectos = this.formatDate(projectResponse.data);
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchInstallation() {
        this.overlay = true;
        this.loading = 3;
        this.first = true;
        try {
            const installResponse = await this.$api.SQLinstallation<rdsa>(
                this.client.split(" - ")[0]
            );
            this.consolidado = JSON.parse(JSON.stringify(this.default));
            if (installResponse.ok) {
                this.installations = installResponse.data;
                this.installs = this.installations;
                this.fetchAll();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            if (this.showButton) {
                this.overlay = false;
            }
        }
    }

    async fetchAll() {
        this.overlay = true;
        this.loading = 4;
        this.elementos = [];
        this.elements = [];
        this.services = {};
        this.servicios = [];
        this.auxServ = [];
        this.consolidado = JSON.parse(JSON.stringify(this.default));
        for (const install of this.installs) {
            try {
                const allResponse = await this.$api.SQLall<rdsa>(
                    install.split(" - ")[0]
                );
                if (allResponse.ok) {
                    this.listElements = allResponse.data["element"];
                    this.toJSON();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
            try {
                const serviceResponse = await this.$api.SQLelementoService<
                    rdsa
                >(install.split(" - ")[0]);
                if (serviceResponse.ok) {
                    this.listServices = serviceResponse.data;
                    this.toJSONservices();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                if (this.showButton) {
                    this.overlay = false;
                }
            }
        }
        if (!this.showButton) {
            this.fetchProject();
            this.getSummary();
        }
    }

    toJSON() {
        try {
            for (const elemento of this.listElements) {
                this.elements.push(elemento[3]);
                this.paramElments[elemento[3]] = {
                    install: elemento[0],
                    box: elemento[1],
                    analyzer: elemento[2],
                    Tdir: elemento[4]
                };
            }
            this.selected = this.elements;
            this.setElements();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    toJSONservices() {
        try {
            this.listServices.forEach(i => {
                if (!this.auxServ.includes(i[5])) {
                    this.auxServ.push(i[5]);
                    this.services[i[5]] = [];
                }
            });
            for (const elemento of this.listServices) {
                this.services[elemento[5]].push(elemento[3]);
                this.paramsServices[elemento[3]] = {
                    install: elemento[0],
                    box: elemento[1],
                    analyzer: elemento[2],
                    Tdir: elemento[4],
                    service: elemento[5]
                };
            }
            this.selectedServices = JSON.parse(JSON.stringify(this.services));
            this.setServices();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    setElements() {
        this.elementos = [];
        for (const elemento of this.selected) {
            if (this.elementos) {
                this.elementos.push({
                    install: this.paramElments[elemento]["install"],
                    box: this.paramElments[elemento]["box"],
                    analyzer: this.paramElments[elemento]["analyzer"],
                    Telement: elemento,
                    Tdir: this.paramElments[elemento]["Tdir"]
                });
            }
        }
    }

    setServices() {
        for (const serv in this.selectedServices) {
            this.selectedServices[serv].forEach(elemento => {
                if (this.servicios) {
                    this.servicios.push({
                        install: this.paramsServices[elemento]["install"],
                        box: this.paramsServices[elemento]["box"],
                        Telement: elemento,
                        Tdir: this.paramsServices[elemento]["Tdir"],
                        analyzer: this.paramsServices[elemento]["analyzer"],
                        service: this.paramsServices[elemento]["service"]
                    });
                }
            });
        }
    }

    async getSummary() {
        this.overlay = true;
        this.loading = 5;

        const JSOON = {
            elements: this.elementos,
            services: this.servicios
        };
        this.showAll = true;
        if (
            (this.company.split(" - ")[0] == "10" ||
                this.userIdentifier == 42) &&
            this.first
        ) {
            this.consolidado = JSON.parse(JSON.stringify(this.dummy));
            if (Number(this.consolidado.ahorro) > 0) {
                this.color = "green lighten-3";
            } else {
                this.color = "red lighten-3";
            }
            sleep(5000);
            this.first = false;
        } else {
            const summaryReq = await this.$api
                .summaryV2<rdsa>(this.client.split(" - ")[0], this.Pmodo, JSOON)
                .then(response => {
                    this.responde = response;
                    this.consolidado = {
                        consolidado: response.data["consolidado"],
                        ahorro: response.data["ahorro"],
                        proyectos: response.data["proyectos"]
                    };
                    if (Number(this.consolidado.ahorro) > 0) {
                        this.color = "green lighten-3";
                    } else {
                        this.color = "red lighten-3";
                    }
                })
                .catch(error => {
                    return error;
                });
        }

        this.showButton = true;
        this.overlay = false;
    }

    formatDate(data) {
        for (const pl of data) {
            pl.Ffinal = new Date(pl.Ffinal).toISOString().split(".")[0];
            let aux = pl.Ffinal.split("T");
            pl.Ffinal = aux[0] + " " + aux[1];
            pl.Finicio = new Date(pl.Finicio).toISOString().split(".")[0];
            aux = pl.Finicio.split("T");
            pl.Finicio = aux[0] + " " + aux[1];
        }
        return data;
    }

    goToProject(proyecto) {
        this.$root.$emit("Checked", proyecto, this.company, this.client);
        window.location.assign("/#/saving");
    }

    resetForm0() {
        this.color = "lighten-3";
        this.clients = [];
        this.client = "";
        this.installations = [];
        this.installs = [];
        this.listElements = [];
        this.paramElments = [];
        this.elements = [];
        this.selected = [];
        this.elementos = [];
        this.services = {};
        this.listServices = [];
        this.paramsServices = [];
        this.selectedServices = {};
        this.servicios = [];
        this.auxServ = [];
        this.consolidado = JSON.parse(JSON.stringify(this.default));
        this.Nproject = "";
    }

    resetForm1() {
        this.color = "lighten-3";
        this.installations = [];
        this.installs = [];
        this.listElements = [];
        this.paramElments = [];
        this.elements = [];
        this.selected = [];
        this.elementos = [];
        this.services = {};
        this.listServices = [];
        this.paramsServices = [];
        this.selectedServices = {};
        this.servicios = [];
        this.auxServ = [];
        this.consolidado = JSON.parse(JSON.stringify(this.default));
        this.Nproject = "";
    }

    resetForm2() {
        this.color = "lighten-3";
        this.listElements = [];
        this.paramElments = [];
        this.elements = [];
        this.selected = [];
        this.elementos = [];
        this.services = {};
        this.listServices = [];
        this.paramsServices = [];
        this.selectedServices = {};
        this.servicios = [];
        this.auxServ = [];
        this.consolidado = JSON.parse(JSON.stringify(this.default));
        this.Nproject = "";
    }

    get isNoEmpty() {
        if (Object.keys(this.services).length == 0) {
            return false;
        } else {
            return true;
        }
    }

    deselectAll() {
        this.color = "lighten-3";
        this.listElements = [];
        this.selected = [];
        this.elementos = [];
        this.services = {};
        this.listServices = [];
        this.selectedServices = {};
        this.servicios = [];
        this.Nproject = "";
        this.consolidado = JSON.parse(JSON.stringify(this.default));
    }
}
